import $ from "jquery";
window.$ = $;
import Validator from "./validator.js";
import {Debounce} from "~/javascripts/common.js";
import axios from 'axios';


const validationInputSelector = ".js-validation-input"
const invalidControlClass = "mk-form-control--invalid";
const feedbackClass = "mk-form-feedback";

const emailExistMsg = "此Email已註冊";
const emailExistNoticeMsg = "驗證email是否以被註冊...";
const emailNonExistMsg = "此email可以使用";
const serverFailMsg = "伺服器錯誤代碼[012]";

const domainExistMsg = "此網域已註冊"
const domainExistNoticeMsg = "驗證網域是否以被註冊...";
const domainNonExistMsg = "此網域可以使用";

class ValidationInput  {
  constructor(inputEl) {
    this.inputEl = inputEl;

    this.setEl();
    this.setValidationByFuncs();
    this.setValidationByServer();
    if(inputEl.classList.contains('js-select2-validation')) { // select2
      $(this.inputEl).on('select2:select', this.keyupHandler.bind(this));
    } else if(this.inputEl.tagName === 'SELECT' || this.inputEl.type === "checkbox" ) { // select and checkbox
      this.inputEl.addEventListener('change', this.keyupHandler.bind(this));
    } else {  // input and textarea
      this.inputEl.addEventListener('keyup', Debounce(this.keyupHandler.bind(this), 100));
    }
    
  }

  toValidate() {
    this.validationLevelBeofred=null;
    this.setInvalid("");

    const errMsg = this.validateByFuncs();
    if (errMsg !== null) {
      this.setInvalid(errMsg);
      this.setFeedbackByValid();
      return;
    }

    if (this.validationByServer){
      this.validateByServer();
      return;
    } 

    this.setValid();
    this.setFeedbackByValid();
  }

  keyupHandler() {
    this.toValidate();
  }

  firstWordToUpperCase(w) {
    if (typeof w !== "string"){
      return "";
    }

    return `${w.charAt(0).toUpperCase()}${w.slice(1)}`;
  }

  multiWordsToUpperCase(w) {
    if (typeof w !== "string"){
      return "";
    }
    
    const wCamel = `${w.replaceAll(/-(\w)/g, function (match, p1, offset, string) { return p1.toUpperCase() })}`
    return `${wCamel.charAt(0).toUpperCase()}${wCamel.slice(1)}`;
  }

  lowerCamelCase(w) {
    if (typeof w !== "string"){
      return "";
    }

    const rule = /-(\w)/g;
    return w.replace(rule, ($0, $1) => {
      return $1.toUpperCase()
    })
  }

  setValidationByFuncs() {
    this.validateFuncNames = [];
    this.validateFuncOpts = {};

    const { validationInputFunc } = this.inputEl.dataset;
    if (typeof validationInputFunc !== "string"){
      console.log("no validation input found");
      return;
    }

    // TODO: 重構
    const funckeyWords = [...new Set(validationInputFunc.split(' '))];
    funckeyWords.forEach((word)=>{
      let funcName = "";

      const isMatchLenWord = word.indexOf("len");
      const isMatchNumberRangeWord = word.indexOf("number-range");
      if (isMatchLenWord == 0) {
        const lenParams = word.split('-');
        const lenWord = `${this.firstWordToUpperCase(lenParams[0])}${this.firstWordToUpperCase(lenParams[1])}`
        funcName = `validate${lenWord}`;

        this.validateFuncOpts[`${lenParams[0]}${this.firstWordToUpperCase(lenParams[1])}`] = lenParams[2];
      } else if (isMatchNumberRangeWord != -1) {
        const params = word.split('-');
        const number = params.pop()
        const keyWord = params.join('-')
        funcName = `validate${this.multiWordsToUpperCase(keyWord)}`

        this.validateFuncOpts[this.lowerCamelCase(keyWord)] = number
      }
      else {
        funcName = `validate${this.multiWordsToUpperCase(word)}`; // setting default funcName
      }
      

      if (typeof Validator[funcName] === "function") {
        this.validateFuncNames.push(funcName);
      } else {
        console.log(`no found Validator["${funcName}"]`)
      }
    });
  }

  setValidationByServer() {
    this.validationByServer = false;

    const {
      validationInputServerFunc,
    } = this.inputEl.dataset;

    if (
        validationInputServerFunc === "email-exist" ||
        validationInputServerFunc === 'email-confirmed'
      ){
      this.validationByServer = true

      this.serverNoticeMsg = emailExistNoticeMsg;
      this.serverSucessMsg = emailNonExistMsg;
      this.serverValidationFunc = Debounce(()=>{

        let quertParams = `email=${this.inputEl.value}`;
        if (validationInputServerFunc === 'email-confirmed') {
          quertParams = `${quertParams}&check_confirmed=1`
        }
        
        axios.get(`/api/users/email_exist?${quertParams}`)
        .then(({ data }) => {
          if (this.validationLevelBeofred === "server"){
            if(data.exists){
              this.setInvalid(emailExistMsg);
            } else {
              this.setValid(this.serverSucessMsg);
            }

            this.setFeedbackByValid();
          }
        })
        .catch((_)=> this.setInvalid(serverFailMsg));
      }, 700);
    }

    if(validationInputServerFunc === "domain-exist"){
      this.validationByServer = true

      this.serverNoticeMsg = domainExistNoticeMsg;
      this.serverSucessMsg = domainNonExistMsg;

      this.serverValidationFunc = Debounce(()=>{
        axios.get(`/api/users/domain_exist?store_slug=${this.inputEl.value}`)
        .then(({ data }) => {
          if (this.validationLevelBeofred === "server"){
            if(data.exists){
              this.setInvalid(domainExistMsg);
            } else {
              this.setValid(this.serverSucessMsg);
            }

            this.setFeedbackByValid();
          }
        })
        .catch((_)=> this.setInvalid(serverFailMsg));
      }, 700);
    }
  }

  validateByServer() {
    this.validationLevelBeofred= "server";
    this.setNoticeFeedback(this.serverNoticeMsg);
    this.serverValidationFunc();
  }

  setEl() {
    const {
      validateInputInvalidFb,
      validateInputInvalidControlClass,
    } = this.inputEl.dataset;

    if (typeof validateInputInvalidFb === "string"){
      this.feedbackClass = validateInputInvalidFb;
    } else {
      this.feedbackClass = feedbackClass;
    }

    if (typeof validateInputInvalidControlClass === "string"){
      this.invalidControlClass = validateInputInvalidControlClass;
    } else {
      this.invalidControlClass = invalidControlClass;
    }

    this.feedbackEl = this.inputEl.parentElement.querySelector(`.${this.feedbackClass}`);
  }

  validateByFuncs() {
    this.validationLevelBeofred= "func";
    let errMsg = null;

    this.validateFuncNames.every((funcName)=>{
      const e = Validator[funcName](this.inputEl.value, this.validateFuncOpts);
      if (e !== "") {
        errMsg = e;
        return false;
      }

      return true;
    });

    return errMsg;
  }

  clearFeedbackStyle() {
    this.feedbackEl.classList.toggle(`${this.feedbackClass}--sucess`, false);
    this.feedbackEl.classList.toggle(`${this.feedbackClass}--invalid`, false);
    this.feedbackEl.classList.toggle(`${this.feedbackClass}--notice`, false);

    this.inputEl.classList.toggle(this.invalidControlClass, false);
  }

  setNoticeFeedback(msg) {
    if (this.feedbackEl === null) {
      return;
    }

    this.clearFeedbackStyle();
    this.feedbackEl.classList.toggle(`${this.feedbackClass}--notice`, true);
    this.feedbackEl.innerHTML = msg;
  }
  

  setFeedbackByValid() {
    if (this.feedbackEl === null) {
      return;
    }

    this.clearFeedbackStyle();
    let msg = null;
    let type = null;
    let hide = false;

    if (this.isValid()) {
      if (this.validMsg !== null) {
        type = "sucess";
        msg = this.validMsg;
      } else {
        hide = true;
      }
    } else {
      msg = this.invalidMsg;
      type = "invalid";
    }

    this.inputEl.classList.toggle(this.invalidControlClass, type === "invalid");

    if (hide) {
      this.feedbackEl.classList.toggle(`${this.feedbackClass}--hide`, true);
    } else {
      this.feedbackEl.classList.toggle(`${this.feedbackClass}--hide`, false);
      this.feedbackEl.classList.toggle(`${this.feedbackClass}--${type}`, true);
      this.feedbackEl.innerHTML = msg;
    }
  }

  setValid(validMsg=null) {
    this.validMsg = validMsg;
    this.invalidMsg = null;
    this.valid = true;
  }

  setInvalid(invalidMsg) {
    this.validMsg = null;
    this.invalidMsg = invalidMsg;
    this.valid = false;
  }

  isValid() {
    return this.valid;
  }
}

export default function NewValidationInputs(formEl) {
  const validationInputs = [];

  formEl
    .querySelectorAll(validationInputSelector)
    .forEach((el) =>{
      validationInputs.push(new ValidationInput(el));
    });
    
  return validationInputs;
};