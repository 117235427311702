import NewValidationInputs from "./validation_form/validation_input.js"

const validationFormSelector = ".js-validation-form";
class ValidationForm {
  constructor(formEl) {
    this.formEl = formEl;
    this.submitFailedCallback = null;
    this.manauallySubmit = false;

    this.inputs = NewValidationInputs(this.formEl);
    if (this.inputs.length < 1) {
      console.log("no validation input found");
      return;
    }
    
    this.formEl.addEventListener("submit", this.submitHandler.bind(this));
  }

  submit(callback) {
    if(callback){
      this.submitFailedCallback = callback;
    }

    this.manauallySubmit = true;
    this.formEl.dispatchEvent(new Event("submit"));
  }

  submitHandler(e) {
    const allInputValid = this.inputs.map((input)=>{
      // TODO: refactor
      if (input.isValid() && input.validationLevelBeofred == "server") {
        return true;
      }

      input.toValidate();
      return input.isValid();
    });

    if (!allInputValid.every(e=> e===true)) {
      e.preventDefault();
      this.scrollToValid(allInputValid);

      if(this.submitFailedCallback) {
        this.submitFailedCallback();
      }

      this.manauallySubmit = false;
      return;
    }

    if(this.manauallySubmit) {
      this.formEl.submit();
    }
  }

  scrollToValid(arr) {
    const firstFalse =  arr.findIndex(function(el){ return el === false; });
    const inputError = document.querySelectorAll('.js-validation-input')[firstFalse]
    const label = document.querySelector("label[for='" + inputError.id + "']");
    const target = label ? label :inputError
    if(!this.isElementInViewport(target)) target.scrollIntoView({ behavior: 'smooth' });
  }

  isElementInViewport(el) {
    const rect = el.getBoundingClientRect();
    return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <= (window.inneValidationFormrWidth || document.documentElement.clientWidth)
    );
  }
}

function InitValidationForms() {
  document
    .querySelectorAll(validationFormSelector)
    .forEach((el) =>{
      new ValidationForm(el);
    });
};

export {
  InitValidationForms,
  ValidationForm
}