import flatpickr from "flatpickr";
import 'flatpickr/dist/flatpickr.css';
import monthSelectPlugin from "flatpickr/dist/plugins/monthSelect";
import 'flatpickr/dist/plugins/monthSelect/style.css';

const flatPickrSelector = '.js-flat-pickr'
const flatPickrMonthSelector = '.js-flat-pickr-month'

export default function NewFlatPickr(options={}) {
  let timeFormat = "Y-m-d";

   const config =  {
    allowInput: true,
    enableTime: false,
    enableSeconds: true,
    dateFormat: timeFormat
  }

  if(!options.ignoreSec) {
    config.dateFormat = `${timeFormat} H:i:S`
    config.enableTime = true;
  }

  if(options.disableMobile) config.disableMobile = true;

  flatpickr(flatPickrSelector, config); 

  const monthConfig = {
    allowInput: true,    
    // disableMobile: true, // 停用local date pickr
    plugins: [new monthSelectPlugin({
      shorthand: true,
      dateFormat: "Y-m",
    })]
  }

  if(options.disableMobile) monthConfig.disableMobile = true;
 
  const userAgent = navigator.userAgent
  if (!(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent)) || monthConfig.disableMobile) {
    flatpickr(flatPickrMonthSelector, monthConfig)
  }
}