import {isEmail, isEmpty} from 'validator';
import axios from 'axios';

const defaultMsg = "不符合格式";

const invalidEmailMsg    = "不符合email格式";
const invalidRequiredMag = "此欄位必填";
const invalidMinxLenMag  = "最少$個字";
const invalidMaxLenMag  = "最多$個字";
const invalidCellphoneMsg = defaultMsg
const invalidAtLeastOneWordMsg = "至少搭配一個英文字母";
const invalidConfirmTokenFormatMag  = "請輸入6位數驗證碼";
const invalidPasswordConfirmationMag  = "再次輸入的密碼與密碼不相同";
const invalidCouponFomatMag = "請使用「數字」、「全大寫英文」、「全大寫英文 + 數字」"
const invalidNumberMsg = "請輸入有效數字"
const invalidLessThanNumberRangeMsg = "必須小於$"
const invalidGreaterThanNumberRangeMsg = "必須大於$"
const invalidMinNumberRangeMsg = "下限為$"
const invalidMaxNumberRangeMsg = "上限為$"
const invalidIntegerMsg = "請輸入有效整數"
const invalidFloat = "請輸入有效小數"
const invalidCheck = "請勾選"
const invalidDomain = "請使用「數字」、「小寫英文」、「-」"

export default class Validator {
  static validateEmail(value, opts={}) {
    const validPattern = isEmpty(value) || isEmail(value);
    if (!validPattern){
      return invalidEmailMsg; 
    }

    return "";
  }

  static validateRequired(value, opts={}) {
    const validPattern = !isEmpty(value);
    if (!validPattern){
      return invalidRequiredMag;
    }

    return "";
  }

  static validateLenMin(value, opts={}) {
    const min = opts.lenMin;

    const validPattern = isEmpty(value) || value.length >= parseInt(min, 10);

    if (!validPattern){
      let msg = invalidMinxLenMag;
      return msg.replace("$", min); 
    }

    return "";
  }

  static validateLenMax(value, opts={}) {
    const max = opts.lenMax;
    const validPattern = isEmpty(value) || value.length <= parseInt(max, 10);

    if (!validPattern){
      let msg = invalidMaxLenMag;
      return msg.replace("$", max); 
    }

    return "";
  }

  static validateCellphone(value, opts={}) {
    const validPattern = isEmpty(value) || value.match(/09\d{2}(\d{6}|-\d{3}-\d{3})/)
    if (!validPattern){
      return invalidCellphoneMsg; 
    }

    return "";
  }

  // 至少一個英文字
  static validateAtLeastOneWord(value, opts={}) {
    const validPattern = isEmpty(value) || value.match(/[a-zA-Z]/)
    if (!validPattern){
      return invalidAtLeastOneWordMsg; 
    }

    return "";
  }

  static validateConfirmTokenFormat(value, opts={}) {
    const validPattern = value.length == 6
    if (!validPattern){
      return invalidConfirmTokenFormatMag; 
    }

    return "";
  }

  static validatePasswordConfirmation(value, opts={}) {
    const origin_password = document.getElementById("user-password")?.value || ""
    if (value !== origin_password) {
      return invalidPasswordConfirmationMag; 
    }

    return "";
  }

  static validateCouponFomat(value, opts={}) { // 使用「數字」「全大寫英文」「全大寫英文 + 數字」
    var regex = /^[A-Z\d]{1,6}$/;
    const validPattern = regex.test(value)
    if (!validPattern) {
      return invalidCouponFomatMag; 
    }

    return "";
  }

  static validateNumber (value, opts={}) { 
    var regex = /^[0-9]*$/;
    const validPattern = regex.test(value)
    if (!validPattern) {
      return invalidNumberMsg; 
    }

    return "";
  }

  static validateLessThanNumberRange(value, opts={}) {
    const lessThan = opts.lessThanNumberRange

    const validPattern = isEmpty(value) || value < parseFloat(lessThan);

    if (!validPattern){
      let msg = invalidLessThanNumberRangeMsg;
      return msg.replace("$", lessThan); 
    }

    return "";
  }

  static validateGreaterThanNumberRange(value, opts={}) {
    const greaterThan = opts.greaterThanNumberRange

    const validPattern = isEmpty(value) || value > parseFloat(greaterThan);

    if (!validPattern){
      let msg = invalidGreaterThanNumberRangeMsg;
      return msg.replace("$", greaterThan); 
    }

    return "";
  }
  
  static validateMinNumberRange(value, opts={}) {
    const min = opts.minNumberRange

    const validPattern = isEmpty(value) || value >= parseFloat(min);

    if (!validPattern){
      let msg = invalidMinNumberRangeMsg;
      return msg.replace("$", min); 
    }

    return "";
  }

  static validateMaxNumberRange(value, opts={}) {
    const max = opts.maxNumberRange

    const validPattern = isEmpty(value) || value <= parseFloat(max);

    if (!validPattern){
      let msg = invalidMaxNumberRangeMsg;
      return msg.replace("$", max); 
    }

    return "";
  }
  
  static validateInteger (value, opts={}) { 
    var regex = /^0$|^[1-9]\d*$/;
    const validPattern = regex.test(value)
    if (!validPattern) {
      return invalidIntegerMsg; 
    }

    return "";
  }

  static validateFloat (value, opts={}) { 
    var regex = /^(0(\.\d+)?|1(\.0*)?)$/;
    const validPattern = regex.test(value)
    if (!validPattern) {
      return invalidFloat; 
    }

    return "";
  }

  static validateDomain (value, opts={}) { 
    var regex = /^[0-9a-z-]+$/;
    const validPattern = regex.test(value)
    if (!validPattern) {
      return invalidDomain; 
    }

    return "";
  }

  // checkBox
  static validateCheck (value, opts={}) { 
    const validPattern = value === 'accept'
    if (!validPattern) {
      return invalidCheck; 
    }
    return "";
  }

}