import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/dist/svg-arrow.css';

const AddToolTip = (el, content) => {
  if (!el) {
    return;
  }
  
  tippy(el, {
    allowHTML: true,
    arrow: false,
    content: content
  });
}

const NewToolTipTags = () => {
  document.querySelectorAll(".js-tool-tip").forEach((el) =>{
    const {
      toolTipContent,
    } = el.dataset;

    if (toolTipContent === null || toolTipContent === "") {
      return;
    }

    tippy(el, {
      allowHTML: true,
      arrow: false,
      content: toolTipContent
    });
  });
}

export {AddToolTip, NewToolTipTags}